<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Modify specifications')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <form class="form" @submit.prevent="submitForm">
        <sunbed-form
          ref="sunbedForm"

          :document="document"

          action="EDIT"
          :disabled="isLoadingForm"
        />
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-primary" :disabled="isLoadingForm" @click.prevent="submitForm">Modify</button>
    </template>
  </b-modal>
</template>

<script>
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

import Equipment from '@/libs/classes/equipment';

import sunbedForm from '@/components/pages/sunbeds/components/sunbed.form.vue';

export default {
  mixins: [Mixins.Modal],
  name: 'modifySunbedSpecifications',
  components: {
    sunbedForm,
  },
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
  },
  data () {
    return {
      options: {},
      isLoadingForm: null,

      document: null,
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:modify-sunbed-specifications:open', this.open);
    this.$eventhub.on('modals:equipment:modify-sunbed-specifications:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:modify-sunbed-specifications:open', this.open);
    this.$eventhub.off('modals:equipment:modify-sunbed-specifications:close', this.close);
  },

  methods: {
    async validate () {
      await validations.validateArray([this.$refs.sunbedForm.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.document);
        }
      } catch (e) {
        this.$errors.handle(e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      if (!this.equipment) {
        throw new Error('Prop \'equipment\' undefined');
      }

      // Reset options
      this.$set(this, 'options', options || {});
      this.$set(this, 'document', this.equipment.clone());

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    onShown () {

    },
    onHide () {
      this.$errors.clear();
    },
  },
};
</script>
