<template>
  <b-carousel>
    <b-carousel-slide v-for="(notification, idx) in notifications" :key="`notification.${idx}`">
      <template v-slot:img>
        <div class="navi navi-icon-circle navi-spacer-x-0">
          <notification-nav-item
            :notification="notification"
          />
        </div>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import equipmentService from '@/libs/equipment';

import notificationNavItem from '@/components/pages/sunbeds/view/components/notification.nav-item.vue';

export default {
  components: {
    notificationNavItem,
  },

  computed: {
    equipment () {
      return this.$store.get('sunbeds:view:equipment');
    },
    equipmentMaintenance () {
      return this.$store.get('sunbeds:view:equipment:maintenance');
    },
    device () {
      return this.$store.get('sunbeds:view:device');
    },

    notifications () {
      return equipmentService.notifications(this.equipment, this.device, {
        maintenance: this.equipmentMaintenance,
      });
    },
  },
};
</script>
