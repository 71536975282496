<template>
  <b-modal
    ref="modal"

    title="Link module"

    size="lg"
    centered
  >
    <template v-slot:default>
      <div class="row d-none d-md-flex">
        <div class="col-auto mx-auto">
          <img :src="require('@/assets/images/module/myluxura-module.sticker.svg')" class="img-fluid" alt="MyLuxura Module">
        </div>
      </div>

      <form class="form" @submit.prevent="submitForm">
        <div class="form-group">
          <label>Module ID <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="['fas', 'cube']" />
                </i>
              </span>
            </div>
            <input
              ref="serialInput"
              type="text"
              :class="['form-control', { 'is-invalid': $v.form.serial.$error }]"
              :disabled="isLoadingForm"
              placeholder="MLXXXXXXXXXXXXXX"
              v-model.trim="$v.form.serial.$model"
            />
          </div>
          <span class="form-text text-muted">The Module ID as printed on the included label.</span>
        </div>

        <div class="form-group">
          <label>Module password <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="['fas', 'key']" />
                </i>
              </span>
            </div>
            <input
              type="text"
              :class="['form-control', { 'is-invalid': $v.form.psk.$error }]"
              :disabled="isLoadingForm"
              placeholder=""
              v-model.trim="$v.form.psk.$model"
            />
          </div>
          <span class="form-text text-muted">The Module password as printed on the included label.</span>
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" :class="['btn', (equipment.isDeviceLinked() ? 'btn-warning' : 'btn-success')]" :disabled="isLoadingForm" @click.prevent="submitForm">
        {{ (equipment.isDeviceLinked() ? 'Replace' : 'Link') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

import Equipment from '@/libs/classes/equipment';

export default {
  mixins: [Mixins.Modal],
  name: 'linkModuleModal',
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
  },
  data () {
    return {
      options: null,
      isLoadingForm: false,

      form: {
        serial: null,
        psk: null,
      },
    };
  },
  validations: {
    form: {
      serial: {
        required,
      },
      psk: {
        required,
      },
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:link-module:open', this.open);
    this.$eventhub.on('modals:equipment:link-module:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:link-module:open', this.open);
    this.$eventhub.off('modals:equipment:link-module:close', this.close);
  },

  methods: {
    async validate () {
      await validations.validateArray([this.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      let response = null;
      try {
        await this.validate();

        response = await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/device/connect`, {
          body: {
            serial: this.form.serial,
            psk: this.form.psk,
          },
        });

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(response);
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      if (!this.equipment) {
        throw new Error('Prop \'equipment\' undefined');
      }

      // Reset options
      this.$set(this, 'options', options);

      // Reset form
      this.$set(this.form, 'serial', null);
      this.$set(this.form, 'psk', null);
      this.$v.$reset();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    onShown () {
      this.$nextTick(() => {
        this.$refs.serialInput.focus();
      });
    },
  },
};
</script>
