<template>
  <!-- begin::Loading -->
  <div v-if="isMounted === false || isLoading === true" class="row">
    <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Loading -->

  <!-- begin::Error -->
  <error-translation-alert
    v-else-if="isErrorActive"
    ref="errorAlert"
    :error="activeError"

    class="container"
  />
  <!-- end::Error -->

  <!-- begin::Content -->
  <div v-else>
    <div class="row">
      <equipment-details-card
        ref="equipmentDetailsCard"

        class="col-12 gutter-b"

        :equipment="equipment"
        :device="device"
      />
    </div>

    <transition name="fade-in-up">
      <router-view
        ref="router-view"
        :equipment="equipment"
        :device="device"
      />
    </transition>

    <!-- begin::Modals -->
    <equipment-modals
      ref="equipmentModals"
      :equipment="equipment"
      :device="device"
    />
    <!-- end::Modals -->
  </div>
  <!-- end::Content -->
</template>

<script>
import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

import { errorComponentMixin } from '@vedicium/core-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

import equipmentDetailsCard from '@/components/pages/sunbeds/view/components/details-card/index.vue';
import equipmentModals from '@/components/pages/sunbeds/view/modals/index.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    errorTranslationAlert,

    equipmentDetailsCard,
    equipmentModals,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      equipment: null,
      device: null,
    };
  },

  async mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Sunbeds', route: '/sunbeds/overview' }]);

    this.$set(this, 'isLoading', true);
    try {
      await this.onEquipmentUpdate(await Equipment.get(this.$route.params.guid), { init: true });
    } catch (e) {
      console.error(e);
      this.$errors.handle(e, { component: this, ui_element: false });
      return;
    } finally {
      this.$set(this, 'isMounted', true);
      this.$set(this, 'isLoading', false);
    }

    await this.$nextTick();

    this.$metronic.breadcrumbs.appendBreadcrumb({ title: this.equipment.serial });

    // Listeners
    this.$ws.socket.on(`equipment:${this.equipment._meta.guid}`, this.onEquipmentUpdate.bind(this));
    this.$eventhub.on(`document:${this.equipment._meta.index}:${this.equipment._meta.guid}:update`, this.onEquipmentUpdate.bind(this));
    if (this.device) {
      this.$eventhub.on(`document:${this.device._meta.index}:${this.device._meta.guid}:update`, this.onDeviceUpdate.bind(this));
    }

    // If no location is set, show location modal
    if (this.equipment && (!this.equipment.location || !this.equipment.location.place_id)) {
      this.$eventhub.emit('equipment:methods:modify-location');
    }
  },
  beforeDestroy () {
    // Remove listeners
    if (this.equipment) {
      this.$ws.socket.off(`equipment:${this.equipment._meta.guid}`);
      this.$eventhub.off(`document:${this.equipment._meta.index}:${this.equipment._meta.guid}:update`, this.onEquipmentUpdate.bind(this));
    }

    if (this.device) {
      this.$ws.socket.off(`device:${this.device._meta.guid}`);
      this.$eventhub.off(`document:${this.device._meta.index}:${this.device._meta.guid}:update`, this.onDeviceUpdate.bind(this));
    }

    // Remove store properties
    this.$store.del('sunbeds:view:equipment');
    this.$store.del('sunbeds:view:equipment:maintenance');
    this.$store.del('sunbeds:view:device');
  },

  methods: {
    async onEquipmentUpdate (document, options = {}) {
      if (!document || !document._meta) {
        return;
      }

      // New equipment
      const equipment = new Equipment().merge(document);

      // Check if device has changed
      if (!this.equipment || equipment.device_guid !== this.equipment.device_guid) {
        this.onDeviceUpdate(equipment.device_guid ? await equipment.getDevice() : null);
      }

      this.$set(this, 'equipment', equipment);
      this.$store.set('sunbeds:view:equipment', equipment);

      // If it's the 'init' request, gather maintenance data
      if (options.init === true) {
        this.getMaintenanceInformation();
      }
    },
    async onDeviceUpdate (document) {
      // New device
      const device = (document ? new Device().merge(document) : null);

      if (device) {
        if (!this.device || device._meta.guid !== this.device._meta.guid) {
          // Listen to device
          this.$ws.socket.on(`device:${device._meta.guid}`, this.onDeviceUpdate.bind(this));
          this.$eventhub.on(`document:${device._meta.index}:${device._meta.guid}:update`, this.onDeviceUpdate.bind(this));

          // Remove listener of old device
          if (this.device) {
            this.$ws.socket.off(`device:${this.device._meta.guid}`);
            this.$eventhub.off(`document:${this.device._meta.index}:${this.device._meta.guid}:update`, this.onDeviceUpdate.bind(this));
          }
        }
      }

      if (!device && this.device) {
        this.$ws.socket.off(`device:${this.device._meta.guid}`);
        this.$eventhub.off(`document:${this.device._meta.index}:${this.device._meta.guid}:update`, this.onDeviceUpdate.bind(this));
      }

      this.$set(this, 'device', device);
      this.$store.set('sunbeds:view:device', device);
    },

    async getMaintenanceInformation () {
      const response = await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/maintenance/counters`);
      this.$store.set('sunbeds:view:equipment:maintenance', response);

      return response;
    },
  },
};
</script>
