<template>
  <ul class="nav nav-tabs nav-tabs-line border-bottom-0" role="tablist">
    <li class="nav-item mr-14 mt-4">
      <router-link :to="{ name: '/sunbeds/view/:guid/general' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
        <span class="nav-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'home']" />
          </i>
        </span>
        <span class="nav-text">General</span>
      </router-link>
    </li>

    <li class="nav-item mr-14 mt-4">
      <router-link :to="{ name: '/sunbeds/view/:guid/health' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
        <span class="nav-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'heartbeat']" />
          </i>
        </span>
        <span class="nav-text">Health</span>
      </router-link>
    </li>

    <li class="nav-item mr-14 mt-4">
      <router-link :to="{ name: '/sunbeds/view/:guid/updates' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
        <span class="nav-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" />
          </i>
        </span>
        <span class="nav-text">Updates</span>
      </router-link>
    </li>

    <li v-if="$auth.hasPermission('equipment.statistics.sessions')" class="nav-item mr-14 mt-4">
      <router-link :to="{ name: '/sunbeds/view/:guid/statistics' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
        <span class="nav-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'analytics']" />
          </i>
        </span>
        <span class="nav-text">Statistics</span>
      </router-link>
    </li>

    <li class="nav-item mr-14 mt-4">
      <router-link :to="{ name: '/sunbeds/view/:guid/settings' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
        <span class="nav-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'cogs']" />
          </i>
        </span>
        <span class="nav-text">Settings</span>
      </router-link>
    </li>

    <li v-if="$auth.isResourceFromIndex('users') && $auth.hasPermission('equipment.service')" class="nav-item mr-14 mt-4">
      <router-link :to="{ name: '/sunbeds/view/:guid/development' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
        <span class="nav-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'file-code']" />
          </i>
        </span>
        <span class="nav-text">Development</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import Equipment from '@/libs/classes/equipment';

export default {
  name: 'equipmentDetailsCardMenu',
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
  },
};
</script>
