<template>
  <div class="card card-custom">
    <div class="card-body border-bottom">
      <div class="row">
        <div class="col-md-12 col-lg-4 pb-5 pb-lg-0 border-bottom border-right-lg border-bottom-lg-0">
          <div class="d-flex">
            <div class="flex-shrink-0 mr-7 d-none d-md-block">
              <div class="symbol symbol-75 symbol-lg-90 symbol-2by3">
                <span class="symbol-label bg-none">
                  <sunbed-image :equipment="equipment" class="h-100" />
                </span>
              </div>
            </div>

            <div class="flex-grow-1 overflow-hidden">
              <div :class="['row', { 'cursor-pointer': $auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.information') }]" @click.prevent="modifyAlias()">
                <div class="col overflow-ellipsis">
                  <span class="text-dark font-weight-bold h4 mb-0">{{ (equipment.alias ? equipment.alias : equipment.serial) }}</span>
                </div>
                <div v-if="$auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.information')" class="col-auto">
                  <i class="text-dark">
                    <font-awesome-icon :icon="['fas', 'pencil']" />
                  </i>
                </div>
              </div>

              <div class="row">
                <div v-if="equipment.alias" class="col-12 overflow-ellipsis">{{ equipment.serial }}</div>
                <div class="col-12 overflow-ellipsis">{{ $tu(`sunbeds.models.${equipment.model}.name`) || equipment.model }}</div>
                <div class="col-12 overflow-ellipsis">{{ $tu(`sunbeds.models.${equipment.model}.types.${equipment.type}`) || equipment.type }}</div>
                <div class="col-12 overflow-ellipsis">{{ format.sunbedHours(equipment.getControllerHours('unit') || 0) }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-4 col-xl-4 py-5 py-lg-0 border-bottom border-right-lg border-bottom-lg-0">
          <div :class="['row', { 'cursor-pointer': $auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.information') }]" @click.prevent="modifyLocation()">
            <div class="col overflow-ellipsis">
              <span :class="['text-dark font-weight-bold h4 mb-0', { 'font-italic': (!equipment.location || !equipment.location.description) }]">{{ (equipment.location && equipment.location.description ? equipment.location.description : 'No location set') }}</span>
            </div>
            <div v-if="$auth.hasPermission('equipment.update') || $auth.hasPermission('equipment.update.information')" class="col-auto">
              <i class="text-dark">
                <font-awesome-icon :icon="['fas', 'pencil']" />
              </i>
            </div>
          </div>

          <!-- begin::No module connected -->
          <div v-if="!equipment.isDeviceLinked()" class="row px-3 mt-3">
            <no-module-linked-alert
              ref="noModuleLinkedAlert"
              class="col-12"
            />
          </div>
          <!-- end::No module connected -->

          <!-- begin::Device information -->
          <template v-else>
            <div class="row">
              <div class="col-6 col-lg-5 col-xl-4">Last seen</div>
              <div class="col font-weight-bold overflow-ellipsis">{{ (device && device.last_ping ? $moment(device.last_ping).fromNow() : 'Never') }}</div>
            </div>

            <!-- <div class="row">
              <div class="col-6 col-lg-5 col-xl-4">Software version</div>
              <div class="col text-brand font-weight-bold overflow-ellipsis">1.0.0</div>
            </div>

            <div class="row">
              <div class="col-6 col-lg-5 col-xl-4">Module version</div>
              <div class="col text-danger font-weight-bold overflow-ellipsis">10e8c5a</div>
            </div> -->
          </template>
          <!-- end::Device information -->
        </div>

        <div class="col-md-12 col-lg-4 col-xl-4 pt-5 pt-lg-0 m-auto">
          <equipment-details-card-carousel
            ref="equipmentDetailsCardCarousel"

            :equipment="equipment"
            :device="device"
          />
        </div>
      </div>
    </div>
    <div class="card-body py-0">
      <div class="row">
        <div class="col-12 col-md-auto">
          <equipment-details-card-menu
            ref="equipmentDetailsCardMenu"

            :equipment="equipment"
            :device="device"
          />
        </div>

        <div class="col my-md-auto text-right">
          <b-dropdown
            ref="quickActionsDropdown"
            variant="light"
            lazy
            right
            no-caret
            class="pb-3 pb-md-0 mr-n7"
          >
            <template v-slot:button-content>
              <i class="text-brand pr-0">
                <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
              </i>
            </template>

            <template v-slot:default>
              <b-dropdown-text tag="div" class="min-w-md-250px dropdown-text-p0 p-0 dropdown-menu-top-unround">
                <!--begin::Navigation-->
                <ul class="navi navi-hover navi-accent navi-active navi-dark py-4">
                  <!-- begin::General actions -->
                  <template v-if="equipment.isDeviceLinked() && $auth.hasPermission('equipment.controller.update') && device.isLegacyDevice() === false">
                    <li class="navi-item navi-item-active">
                      <router-link
                        :to="{ name: '/sunbeds/view/:guid/updates/sunbed', query: { update_cycle: null } }"
                        class="navi-link"
                      >
                        <span class="navi-text">Update sunbed</span>
                      </router-link>
                    </li>
                  </template>

                  <template v-if="$auth.hasPermission('companies.equipment.link')">
                    <li class="navi-item navi-item-active">
                      <router-link
                        :to="{ name: '/sunbeds/view/:guid/settings/link-company' }"
                        class="navi-link"
                      >
                        <span class="navi-text">Link company</span>
                      </router-link>
                    </li>

                    <li class="navi-separator my-0" />
                  </template>
                  <!-- end::General actions -->

                  <!-- begin::Sunbed -->
                  <template v-if="equipment.isDeviceLinked() && $auth.hasPermission('equipment.controller.reset') && device.isLegacyDevice() === false">
                    <li class="navi-item navi-item-active">
                      <router-link
                        :to="{ name: '/sunbeds/view/:guid/settings/options', query: { restart_sunbed: null } }"
                        class="navi-link"
                      >
                        <span class="navi-text text-warning">Restart sunbed</span>
                      </router-link>
                    </li>

                    <li class="navi-separator my-0" />
                  </template>
                  <!-- end::Sunbed -->

                  <!-- begin::Module -->
                  <template v-if="!equipment.isDeviceLinked()">
                    <li class="navi-item navi-item-active">
                      <router-link
                        :to="{ name: '/sunbeds/view/:guid/settings/module', query: { 'link-module': null } }"
                        class="navi-link"
                      >
                        <span class="navi-text text-info">Link module</span>
                      </router-link>
                    </li>

                    <li class="navi-separator my-0" />
                  </template>

                  <template v-if="equipment.isDeviceLinked()">
                    <li class="navi-item navi-item-active">
                      <router-link
                        :to="{ name: '/sunbeds/view/:guid/settings/module', query: { 'link-module': null } }"
                        class="navi-link"
                      >
                        <span class="navi-text text-warning">Replace module</span>
                      </router-link>
                    </li>

                    <li class="navi-separator my-0" />
                  </template>
                  <!-- end::Module -->

                  <li v-if="$auth.isResourceFromIndex('users') && $auth.hasPermission('equipment.delete')" class="navi-item navi-item-active">
                    <router-link
                      :to="{ name: '/sunbeds/view/:guid/settings/options', query: { 'remove': null } }"
                      class="navi-link"
                    >
                      <span class="navi-text text-danger">Delete</span>
                    </router-link>
                  </li>
                </ul>
                <!-- end::Navigation -->
              </b-dropdown-text>
            </template>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import objectPath from 'object-path';
import { mutations as mutationsLib } from '@vedicium/core-vue';
import format from '@/libs/format';

import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

import equipmentDetailsCardMenu from '@/components/pages/sunbeds/view/components/details-card/menu.vue';
import equipmentDetailsCardCarousel from '@/components/pages/sunbeds/view/components/details-card/carousel.vue';
import sunbedImage from '@/components/pages/sunbeds/components/sunbed-image/index.vue';
import noModuleLinkedAlert from '@/components/pages/sunbeds/view/components/no-module-linked.alert.vue';

export default {
  name: 'equipmentDetailsCard',
  components: {
    equipmentDetailsCardMenu,
    equipmentDetailsCardCarousel,
    sunbedImage,
    noModuleLinkedAlert,
  },
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    device: {
      type: Device,
      default: null,
    },
  },
  data () {
    return {
      format,
    };
  },
  watch: {
    $route () {
      this.$refs.quickActionsDropdown.hide();
    },
  },

  mounted () {
    this.$eventhub.on('equipment:methods:modify-location', this.modifyLocation);
  },
  beforeDestroy () {
    this.$eventhub.off('equipment:methods:modify-location', this.modifyLocation);
  },

  methods: {
    modifyAlias () {
      if (this.$auth.isResourceFromIndex('users')) {
        if (!this.$auth.hasPermission('equipment.update') && !this.$auth.hasPermission('equipment.update.information')) {
          return;
        }
      }

      const vm = this;
      vm.$eventhub.emit('modals:equipment:modify-field:open', {
        title: 'Modify nickname',
        label: 'Nickname',
        value: objectPath.get(vm.equipment, 'alias'),
        async onSubmit (value) {
          const equipment = vm.equipment.clone();
          objectPath.set(equipment, 'alias', value);

          // Create mutations
          const mutations = mutationsLib.create(['alias'], vm.equipment.formatBody('update'), equipment.formatBody('update'));
          if (mutations.length === 0) {
            return;
          }

          const equipmentDocument = new Equipment().merge(await vm.$ws.patch(`${Equipment.uri}/${vm.equipment._meta.guid}/information`, {
            body: mutations,
          }));

          vm.$eventhub.emit(`document:${equipmentDocument._meta.index}:${equipmentDocument._meta.guid}:update`, equipmentDocument);
        },
      });
    },

    modifyLocation () {
      if (this.$auth.isResourceFromIndex('users')) {
        if (!this.$auth.hasPermission('equipment.update') && !this.$auth.hasPermission('equipment.update.information')) {
          return;
        }
      }

      const vm = this;
      vm.$eventhub.emit('modals:equipment:modify-location:open', {
        async onSubmit (location) {
          const equipment = vm.equipment.clone().merge({
            location: {
              place_id: location.place_id,
              description: location.description,
            },
          });

          const mutations = mutationsLib.create(['location.place_id'], vm.equipment.formatBody('update'), equipment.formatBody('update'));
          if (mutations.length === 0) {
            return;
          }

          const equipmentDocument = new Equipment().merge(await vm.$ws.patch(`${Equipment.uri}/${vm.equipment._meta.guid}/information`, {
            body: mutations,
          }));

          vm.$eventhub.emit(`document:${equipmentDocument._meta.index}:${equipmentDocument._meta.guid}:update`, equipmentDocument);
        },
      });
    },
  },
};
</script>
