<template>
  <div>
    <link-module-modal ref="linkModuleModal" :equipment="equipment" />
    <modify-location-modal ref="modifyLocationModal" :equipment="equipment" />
    <modify-field-modal ref="modifyFieldModal" :equipment="equipment" />
    <modify-sunbed-specifications-modal ref="modifySunbedSpecificationsModal" :equipment="equipment" />
  </div>
</template>

<script>
import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

import linkModuleModal from './link-module/index.vue';
import modifyLocationModal from './modify-location/index.vue';
import modifyFieldModal from './modify-field/index.vue';
import modifySunbedSpecificationsModal from './modify-sunbed-specifications/index.vue';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    device: {
      type: Device,
      default: null,
    },
  },
  components: {
    linkModuleModal,
    modifyLocationModal,
    modifyFieldModal,
    modifySunbedSpecificationsModal,
  },
};
</script>
